import { ReactNode } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import logo from "../../assets/images/logo-fullip.png";
import voicemeLogo from "../../assets/images/voiceme-bouble.svg"; // Logo di Voiceme

export function VoicemeOIDCLayout({ children }: { children?: ReactNode }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                background: "linear-gradient(75deg, #000e38 4%, #000e38 30%, #009f9b 64%, #009f9b 94%) !important",
                color: "#FFFFFF", // Imposta colore bianco
                "& *": {
                    color: "inherit !important", // Forza tutti i figli a ereditare il colore
                },
            }}
        >
            <CssBaseline />

            {/* Header con il logo */}
            <Box
                component="header"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Allinea gli elementi a sinistra
                    padding: "16px 32px",
                }}
            >
                {/* Logo principale */}
                <img
                    src={logo}
                    alt="Froggy Network Logo"
                    style={{
                        height: "150px", // Altezza del logo aumentata
                        objectFit: "contain",
                    }}
                />

                {/* Powered by Voiceme */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 50px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                >
                    Powered by&nbsp;<a
                        href="https://www.voiceme.id"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: "#ffffff", // Colore Voiceme
                            textDecoration: "none",
                            fontWeight: "bold",
                        }}
                    >
                        Voiceme
                    </a>
                </Box>
            </Box>

            {/* Contenuto principale */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    {children}
                </Box>
            </Box>

            {/* Footer */}
            <Box
                component="footer"
                sx={{
                    backgroundColor: "#000e38",
                    textAlign: "center",
                    padding: "16px",
                    marginTop: "auto",
                }}
            >
                © 2024 Froggy Network. All rights reserved.
            </Box>
        </Box>
    );
}
