import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../organism/Loader';
import { useEffect } from 'react';
import { NotificationPayload, OIDCPayload } from '../../../app/api/types';
import { oidcVoiceOtpNotify, oidcVoiceOtpReset, oidcVoiceOtpStart } from '../../../app/oidc-otp/actions';
import { selectorOidcOtpState } from '../../../app/oidc-otp/selector';
import FormEmailService from '../../organism/FormEmailService';
import Message from '../../organism/Message';
import { useNavigate } from 'react-router';

interface Prop {    
    okComplete?: () => void
    okOnError?: () => void
}

function OidcOtp({ okComplete, okOnError}: Prop) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const flow = useSelector(selectorOidcOtpState)
    const handleVoiceOTP = (request: NotificationPayload & { submitButton: string}) => {
        dispatch(oidcVoiceOtpNotify(request))
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const oidcPayload: OIDCPayload = {
            email: urlParams.get('email') || '',
            client_id: urlParams.get('client_id') || '',
            redirect_uri: urlParams.get('redirect_uri') || '',
            next: urlParams.get('next') || '',
            scope: urlParams.get('scope') || '',
            response_type: urlParams.get('response_type') as OIDCPayload['response_type'] || 'token',
            response_mode: urlParams.get('response_mode') as OIDCPayload['response_mode'] || 'query',
            state: urlParams.get('state') || '',
            nonce: urlParams.get('nonce') || '',
            code_challenge_method: urlParams.get('code_challenge_method') as OIDCPayload['code_challenge_method'] || undefined,
            code_challenge: urlParams.get('code_challenge') || undefined,
        };
        dispatch(oidcVoiceOtpStart(oidcPayload))
        if(!!oidcPayload.email) setTimeout(()=>handleVoiceOTP({service_id: "VOICEME", submitButton: "OTP", email: oidcPayload.email!}), 300)
    }, [dispatch])
    switch(flow.status) {
        case 'request-otp-notify':
            return (<Loader/>);
        case 'otp-success':
            return (<Message title="Authentication complete!" severity='success' onClick={okComplete}  ></Message>)
        case 'otp-fail':
            return (<Message title="Authentication failed!" severity='error' onClick={okComplete}  ></Message>)
        case 'request-otp-auth':
        default:
            return (<FormEmailService onClick={handleVoiceOTP}></FormEmailService>);
    }
}

export default OidcOtp;
