import VoicemeRecorder from '../../organism/recorder/VoicemeRecorder';
import { useAppDispatch } from '../../../app/hooks';
import { useSelector } from 'react-redux';
import { selectorAuthWebRequest } from '../../../app-view/auth-web';
import { authWebRequest } from '../../../app/auth-web/actions';
import { AuthorizePayload } from '../../../app/api/types';

interface Prop {
}

function LoginWeb({ }: Prop) {
    const dispatch = useAppDispatch()
    const authWeb = useSelector(selectorAuthWebRequest)

    const handleRecording = (payload: AuthorizePayload) => {
        const requestLogin = {
            ...payload,
            email: authWeb.email!,
            session_id: authWeb.session_id!,
        }
        dispatch(authWebRequest(requestLogin))
    }
    return (
        <>
            <VoicemeRecorder height={600} width={600} sentence={authWeb.sentence} action={handleRecording} attempt={3} duration={3} cancel={() => console.log('cancel')}></VoicemeRecorder>
        </>
    );
}

export default LoginWeb;
