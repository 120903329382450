import { useEffect, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import { IRecording, RecorderInstance } from "./Recorder";
import { Button, Typography } from "@mui/material";
import voicemeBouble from "../../../assets/images/voiceme-bouble.svg";
import voicemeBoubleRed from "../../../assets/images/voiceme-bouble-red.svg";

interface StyleProps {
    isRecordingStart: boolean;
}

interface Props {
    sentence?: string;
    action: (payload: any) => void;
    cancel: () => void;
    attempt?: number;
    duration?: number;
    width?: string | number; // Larghezza personalizzabile
    height?: string | number; // Altezza personalizzabile
}

const VoicemeRecorder = ({
    sentence,
    action,
    attempt,
    duration = 5,
    cancel = () => {},
    width = 400, // Default width
    height = 400, // Default height
}: Props) => {
    const [videoStream, setVideoStream] = useState<MediaStream>();
    const [isRecordingStart, setIsRecordingStart] = useState<boolean>(false);

    const authorizeVoiceme = async ({ video, videoType, audio, audioType }: IRecording) => {
        action({
            email: "sicilia.cristian@gmail.com",
            session_id: "",
            audio: {
                type: audioType,
                dataURL: audio,
            },
            video: {
                type: videoType,
                dataURL: video,
            },
        });
    };

    const startRecording = (recordingDuration: number) => {
        if (videoStream) {
            if (!isRecordingStart) {
                RecorderInstance.startVideoRecording();
                setIsRecordingStart(true);
                setTimeout(() => {
                    requestRecording();
                }, recordingDuration * 1000);
            }
        } else {
            alert("Allow your browser to use your camera and microphone");
        }
    };

    const requestRecording = async () => {
        const recording = await RecorderInstance.requestRecording();
        setIsRecordingStart(false);
        authorizeVoiceme(recording);
    };

    const getPermissions = async () => {
        const videoStream = await RecorderInstance.getVideoStream();
        setVideoStream(videoStream);
    };

    const onClickCancel = () => {
        cancel();
    };

    useEffect(() => {
        if ("MediaRecorder" in window) {
            getPermissions().catch(console.error);
        } else {
            console.log(`The MediaRecorder API is not supported in your browser`);
        }
    }, []);

    return (
        <div
            style={{
                width: typeof width === "number" ? `${width}px` : width,
                height: typeof height === "number" ? `${height}px` : height,
                position: "relative",
                backgroundColor: "#000", // Background del quadrato
                border: "2px solid white",
                borderRadius: "8px",
                overflow: "hidden", // Confinare il contenuto
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {/* Contenitore video */}
            {videoStream && (
                <VideoPlayer
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Adattamento del video
                        zIndex: 1,
                    }}
                    srcObject={videoStream}
                />
            )}

            {/* Bolla e azioni */}
            <div
                style={{
                    position: "absolute",
                    bottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 2,
                }}
            >
                <Button
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: 0,
                    }}
                    onClick={() => startRecording(duration)}
                >
                    <img
                        src={isRecordingStart ? voicemeBoubleRed : voicemeBouble}
                        alt="Record Button"
                        style={{
                            width: "60px",
                            height: "60px",
                        }}
                    />
                </Button>
            </div>

            {/* Frase */}
            {sentence && (
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        textAlign: "center",
                        zIndex: 2,
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Sfondo semi-trasparente
                        padding: "8px 16px",
                        borderRadius: "4px",
                    }}
                >
                    <Typography variant="h6">Look at the webcam and read:</Typography>
                    <Typography variant="h5">{sentence}</Typography>
                </div>
            )}
        </div>
    );
};

export default VoicemeRecorder;
