import { createSlice } from '@reduxjs/toolkit';
import { OidcVoiceOtp } from './types';
import { oidcSuccess, oidcVoiceOtpNotify, oidcVoiceOtpReset, oidcVoiceOtpStart } from './actions';
import { notificationGetFailed } from '../api/actions';

const initialState: OidcVoiceOtp = {
    status: "request-otp-auth"
};

export const oidcOtp = createSlice({
  name: 'oidc-otp',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(oidcVoiceOtpReset, (state, action) => {
      state.status = "request-otp-auth"
      state.oidcParms = undefined
    })
    .addCase(oidcVoiceOtpStart, (state, action) => {
      state.oidcParms = action.payload
    })
    .addCase(oidcVoiceOtpNotify, (state, action) => {
      state.status = "request-otp-notify"
    })
    .addCase(notificationGetFailed, (state) => {
        state.status = "otp-fail"
    })
    .addCase(oidcSuccess, (state) => {
      state.status = "otp-success"
    })
  },
});

export default oidcOtp.reducer;
